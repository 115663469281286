import React from "react";
import { useMount } from "../hooks/useMount";
import { useWordingsContext } from "../context/WordingsContext";
import { useThemeContext } from "../context/ThemeContext";

export default function Layout({ children }) {
  const { getText } = useWordingsContext();
  const { theme } = useThemeContext();

  useMount(() => {
    document.title = getText("{APP_SHORT_TITLE}");
  });

  return <div style={theme.layout}>{children}</div>;
}
