import React from "react";
import { useNavigate } from "react-router-dom";
import { useSettingsContext } from "../context/SettingsContext";
import { useSessionContext } from "../context/SessionContext";
import { useMount } from "../hooks/useMount";

export default function Continue() {
  const navigate = useNavigate();
  const { continueUrl } = useSettingsContext();
  const { offerId, stepId, setStepId } = useSessionContext();

  useMount(() => {
    if (continueUrl && !offerId) {
      return window.location.replace(continueUrl);
    }

    if (stepId === "pca") {
      setStepId("pcaOk");
    }
    return navigate(`/questions/${offerId}`, { replace: true });
  });

  return null;
}
