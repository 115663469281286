import React, { useEffect, useMemo } from "react";
import { useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { getFlow } from "../constants/flows";
import TextboxInput from "./TextboxInput";
import LongTextboxInput from "./LongTextboxInput";
import CheckboxInput from "./CheckboxInput";
import TyCCheckboxInput from "./TyCCheckboxInput";
import RadioInput from "./RadioInput";
import SelectInput from "./SelectInput";
import Autocomplete from "./AutocompleteInput";
import { useThemeContext } from "../context/ThemeContext";
import { useSessionContext } from "../context/SessionContext";
import { useRecordEvent } from "../hooks/useRecordEvent";
import { findByEmail } from "../services/candidates";
import { getProcess, newProcess, updateProcess } from "../services/process";
import parseBoolean from "../helpers/parseBoolean";
import { isAxiosError } from "axios";
import { useSettingsContext } from "../context/SettingsContext";
import { useWordingsContext } from "../context/WordingsContext";

const inputs = {
  autocomplete: Autocomplete,
  checkbox: CheckboxInput,
  tyccheckbox: TyCCheckboxInput,
  radio: RadioInput,
  select: SelectInput,
  text: TextboxInput,
  long_text: LongTextboxInput,
};

export default function QuestionsWizard() {
  const { themeId } = useSettingsContext();
  const { getText } = useWordingsContext();

  const { flow, steps } = useMemo(
    () => getFlow(themeId, getText),
    [themeId, getText]
  );

  const {
    offerId,
    candidateId,
    setCandidateId,
    stepId,
    setStepId,
    process,
    setProcess,
  } = useSessionContext();

  const body = flow[stepId];
  const nextStepId = steps[steps.indexOf(stepId) + 1];
  const nextStep = nextStepId ? flow[nextStepId] : null;

  const recordEvent = useRecordEvent();

  const { theme } = useThemeContext();

  useEffect(() => {
    recordEvent({
      type: "event",
      name: `Show ${stepId} step`,
    });

    if (stepId === "tyc") {
      setCandidateId(null);
      setProcess(null);
    }
  }, [recordEvent, setCandidateId, setProcess, stepId]);

  const formMethods = useForm();

  const { register, watch, handleSubmit, reset } = formMethods;

  const data = watch();

  useEffect(() => {
    reset(undefined, { keepValues: true });
  }, [reset, stepId]);

  const onSubmitHandler = useCallback(
    async ({
      whiteCard,
      whiteCardExpirationMonth,
      whiteCardExpirationYear,
      foodHandlingCard,
      foodHandlingCardExpirationMonth,
      foodHandlingCardExpirationYear,
      currentWorking,
      ...data
    }) => {
      recordEvent({
        type: "event",
        name: `Click continue into ${stepId} step`,
      });

      if (process && body.goTo) {
        const redirectToUrl = body.goTo(process);
        if (redirectToUrl && redirectToUrl !== "nextStep") {
          setTimeout(() => {
            window.location.href = redirectToUrl;
          }, 500);
          return;
        }
      }

      const payload = {
        offerId,
        currentWorking: parseBoolean(currentWorking),
        whiteCard: parseBoolean(whiteCard),
        whiteCardExpiration: parseBoolean(whiteCard)
          ? new Date(
              Number(whiteCardExpirationYear),
              Number(whiteCardExpirationMonth) - 1
            ).toISOString()
          : undefined,
        foodHandlingCard: parseBoolean(foodHandlingCard),
        foodHandlingCardExpiration: parseBoolean(foodHandlingCard)
          ? new Date(
              Number(foodHandlingCardExpirationYear),
              Number(foodHandlingCardExpirationMonth) - 1
            ).toISOString()
          : undefined,
        ...data,
      };

      let newProcessFlag = false;
      let newCandidateId = candidateId;

      let response;

      if (!candidateId && data.email) {
        let { id: _candidateId } = await findByEmail({
          email: data.email,
        }).catch((err) => {
          if (isAxiosError(err) && err.response?.status === 404) {
            return {};
          }
          throw err;
        });

        if (!_candidateId) {
          newProcessFlag = true;
        } else {
          newCandidateId = _candidateId;
          newProcessFlag = !Boolean(
            await getProcess({ offerId, candidateId: _candidateId }).catch(
              (err) => {
                if (isAxiosError(err) && err.response?.status === 404) {
                  return false;
                }
                throw err;
              }
            )
          );
        }
      }

      if (newProcessFlag) {
        response = await newProcess(payload);
        const { candidateId: _candidateId } = response;
        newCandidateId = _candidateId;
      } else {
        response = await updateProcess({
          candidateId: newCandidateId,
          ...payload,
        });
      }

      setCandidateId(newCandidateId);
      setProcess(response);

      setStepId(nextStepId);
    },
    [
      recordEvent,
      stepId,
      process,
      body,
      offerId,
      candidateId,
      setCandidateId,
      setProcess,
      setStepId,
      nextStepId,
    ]
  );

  return (
    <FormProvider {...formMethods}>
      <div className="tims-questions-container">
        <h1 style={theme.text.primary}>{getText(body.title)}</h1>
        {/* {body.index ? (
          <div className="mb-3">
            <span style={theme.badge.primary}>
              {body.index} de {totalSteps}
            </span>
          </div>
        ) : null} */}
        {body.subtitle?.length
          ? body.subtitle.map((sub) => (
              <p className="mt-3 mb-3" style={theme.text.primary}>
                {sub}
              </p>
            ))
          : null}
        <form className="mt-5 pb-5" onSubmit={handleSubmit(onSubmitHandler)}>
          {body.fields
            ?.filter(({ showWhen }) => !showWhen || showWhen(data))
            .map(({ validations, showWhen, ...props }) => {
              const Input = inputs[props.type] || inputs.text;
              return (
                <Input
                  {...props}
                  key={props.name}
                  {...register(
                    props.name,
                    typeof validations === "function"
                      ? validations(formMethods)
                      : validations
                  )}
                />
              );
            })}
          {nextStep ? (
            <div className="d-flex justify-content-center mt-5">
              <button
                type="submit"
                className="btn btn-lg btn-primary"
                style={theme.button.primary}
              >
                Continuar
              </button>
            </div>
          ) : null}
        </form>
      </div>
    </FormProvider>
  );
}
