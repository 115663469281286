import React, { useState, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useThemeContext } from "../context/ThemeContext";

const AutocompleteInput = React.forwardRef(
  ({ label, source, params, mapper, ...props }, ref) => {
    const { theme } = useThemeContext();

    const {
      watch,
      resetField,
      formState: { errors, isSubmitted },
    } = useFormContext();

    const data = watch();

    const currentValue = watch(props.name);

    const [items, setItems] = useState([]);

    const sourceArgs = useMemo(() => {
      let args = {};
      if (typeof params === "function" && data) {
        args = params(data);
      }
      return JSON.stringify(args);
    }, [data, params]);

    useEffect(() => {
      const args = JSON.parse(sourceArgs);
      if (args !== false) {
        source(args)
          .then(setItems)
          .catch((_) => {
            setItems([]);
            resetField(props.name, { value: "" });
          });
      } else {
        setItems([]);
        resetField(props.name, { value: "" });
      }
    }, [sourceArgs, source, props.name, resetField]);

    useEffect(() => {
      if (
        currentValue &&
        items.length &&
        !items.map(mapper).some((x) => x.value == currentValue)
      ) {
        resetField(props.name, { value: "" });
      }
    }, [items, currentValue, resetField, props.name, mapper]);

    const error = errors[props.name];

    const classes = `form-control form-control-lg ${
      isSubmitted ? (!error ? "is-valid" : "is-invalid") : ""
    }`;

    return (
      <div className="mt-4">
        <label
          htmlFor={props.name}
          className="form-label fw-semibold"
          style={theme.text.primary}
        >
          {label}
        </label>
        <select
          {...props}
          ref={ref}
          id={props.name}
          className={classes}
          defaultValue=""
          value={data[props.name]}
        >
          <option disabled value="">
            Elige un valor
          </option>
          {items.map(mapper).map(({ value, text }) => {
            return (
              <option key={value} value={value}>
                {text || value}
              </option>
            );
          })}
        </select>
        {error && <div className="invalid-feedback">{error.message}</div>}
      </div>
    );
  }
);

AutocompleteInput.displayName = "AutocompleteInput";

export default AutocompleteInput;
