import axios from "axios";

const instance = axios.create({
  timeout: 5000,
});

export async function newProcess({
  offerId,
  email,
  name,
  firstSurname,
  secondSurname,
  documentId,
  gender,
  phone,
  provinceId,
  stateId,
  municipalityId,
  address,
  fullTimeAvailability,
  weekendsAvailability,
  forHoursAvailability,
  whiteCard,
  whiteCardExpiration,
  foodHandlingCard,
  foodHandlingCardExpiration,
  currentWorking,
  educationLevel,
  friends,
  disability,
  storeIds,
}) {
  const response = await instance.post(`processes/${offerId}`, {
    email,
    name,
    firstSurname,
    secondSurname,
    documentId,
    gender,
    phone,
    provinceId,
    stateId,
    municipalityId,
    address,
    fullTimeAvailability,
    weekendsAvailability,
    forHoursAvailability,
    whiteCard,
    whiteCardExpiration: whiteCardExpiration || undefined,
    foodHandlingCard,
    foodHandlingCardExpiration: foodHandlingCardExpiration || undefined,
    currentWorking,
    educationLevel,
    friends: friends || undefined,
    disability: disability || undefined,
    storeIds,
  });
  return response.data;
}

export async function getProcess({ offerId, candidateId }) {
  const response = await instance.get(`processes/${offerId}/${candidateId}`);
  return response.data;
}

export async function updateProcess({
  offerId,
  candidateId,
  name,
  firstSurname,
  secondSurname,
  documentId,
  gender,
  phone,
  provinceId,
  stateId,
  municipalityId,
  address,
  fullTimeAvailability,
  weekendsAvailability,
  forHoursAvailability,
  whiteCard,
  whiteCardExpiration,
  foodHandlingCard,
  foodHandlingCardExpiration,
  currentWorking,
  educationLevel,
  friends,
  disability,
  storeIds,
}) {
  const response = await instance.patch(`processes/${offerId}/${candidateId}`, {
    name,
    firstSurname,
    secondSurname,
    documentId,
    gender,
    phone,
    provinceId,
    stateId,
    municipalityId,
    address,
    fullTimeAvailability,
    weekendsAvailability,
    forHoursAvailability,
    whiteCard,
    whiteCardExpiration: whiteCardExpiration || undefined,
    foodHandlingCard,
    foodHandlingCardExpiration: foodHandlingCardExpiration || undefined,
    currentWorking,
    educationLevel,
    friends: friends || undefined,
    disability: disability || undefined,
    storeIds,
  });
  return response.data;
}

export async function deleteProcess({ offerId, candidateId }) {
  const response = await instance.delete(`processes/${offerId}/${candidateId}`);
  return response.data;
}
