import React from "react";
import Home from "./pages/Home";
import Intro from "./pages/Intro";
import Questions from "./pages/Questions";
import Continue from "./pages/Continue";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: "/intro/:offerId",
    element: <Intro />,
  },
  {
    path: "/questions/:offerId",
    element: <Questions />,
  },
  {
    path: "/continue",
    element: <Continue />,
  },
];

export default AppRoutes;
