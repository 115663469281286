import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Layout from "./components/Layout";
import { SettingsContextProvider } from "./context/SettingsContext";
import { ThemeContextProvider } from "./context/ThemeContext";
import { SessionContextProvider } from "./context/SessionContext";
import "./App.css";
import { WordingsContextProvider } from "./context/WordingsContext";

export default function App() {
  return (
    <SettingsContextProvider>
      <WordingsContextProvider>
        <ThemeContextProvider>
          <SessionContextProvider>
            <Layout>
              <Routes>
                {AppRoutes.map((route, index) => (
                  <Route key={index} {...route} />
                ))}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Layout>
          </SessionContextProvider>
        </ThemeContextProvider>
      </WordingsContextProvider>
    </SettingsContextProvider>
  );
}
