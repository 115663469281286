import React from "react";
import { useFormContext } from "react-hook-form";
import { useThemeContext } from "../context/ThemeContext";

const TextboxInput = React.forwardRef(({ label, ...props }, ref) => {
  const { theme } = useThemeContext();

  const {
    formState: { errors, isSubmitted },
  } = useFormContext();

  const error = errors[props.name];

  const classes = `form-control form-control-lg ${
    isSubmitted ? (!error ? "is-valid" : "is-invalid") : ""
  }`;

  return (
    <div className="mt-4">
      <label
        htmlFor={props.name}
        className="form-label fw-semibold"
        style={theme.text.primary}
      >
        {label}
      </label>
      <input {...props} ref={ref} id={props.name} className={classes} />
      {error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  );
});

TextboxInput.displayName = "TextboxInput";

export default TextboxInput;
