import React from "react";
import { useFormContext } from "react-hook-form";
import { useThemeContext } from "../context/ThemeContext";

const CheckboxInput = React.forwardRef(({ label, ...props }, ref) => {
  const { theme } = useThemeContext();

  const {
    formState: { errors, isSubmitted },
  } = useFormContext();

  const error = errors[props.name];

  const classes = `form-check-input ${
    isSubmitted ? (!error ? "is-valid" : "is-invalid") : ""
  }`;

  return (
    <div className="form-check mt-4">
      <input
        {...props}
        className={classes}
        type="checkbox"
        ref={ref}
        id={`cb_${props.name}`}
      />
      <label
        className="form-check-label"
        style={theme.text.primary}
        htmlFor={`cb_${props.name}`}
      >
        {label}
      </label>
      {error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  );
});

CheckboxInput.displayName = "CheckboxInput";

export default CheckboxInput;
