import React, { useContext, useMemo, useState } from "react";
import { useSettingsContext } from "./SettingsContext";

const ThemeContext = React.createContext(null);

const themeBuilder = ({ themeId, primaryColor }) => ({
  button: {
    primary: {
      color: "#ffffff",
      backgroundColor: primaryColor,
      borderColor: "unset",
    },
    secondary: {},
  },
  text: {
    primary: {
      color: primaryColor,
    },
    secondary: {},
  },
  navbar: {
    color: "#ffffff",
    backgroundColor: primaryColor,
  },
  badge: {
    primary: {
      padding: "4px",
      backgroundColor: primaryColor,
      color: "#ffffff",
      borderRadius: "8px",
    },
  },
  landing: {
    imageUrl: `assets/${themeId}/img/landing-1024x320.jpg`,
    imageAlt: "Trabaja con nosotros",
  },
  layout: {
    minHeight: "100vh",
    backgroundColor: "#F1F3F4",
    backgroundImage: `url('assets/${themeId}/img/background.jpg')`,
    backgroundSize: "cover",
  },
});

export function ThemeContextProvider({ children }) {
  const [theme, setTheme] = useState("default");

  const { themeId } = useSettingsContext();

  const themes = useMemo(() => {
    const defaultTheme = themeBuilder({
      themeId,
      primaryColor: themeId === "spoon" ? "#AE111A" : "#702082",
    });

    const homeTheme = {
      ...defaultTheme,
      layout: {},
    };

    return {
      default: defaultTheme,
      home: homeTheme,
    };
  }, [themeId]);

  const contextValue = useMemo(
    () => ({ theme: themes[theme] ?? themes["default"], setTheme }),
    [themes, theme]
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useThemeContext() {
  return useContext(ThemeContext);
}
