import axios from "axios";

const instance = axios.create({
  timeout: 5000,
});

export async function findOffers() {
  const response = await instance.get("offers?filter[enabled]=true");
  return response.data;
}

export async function findStores({ offerId }) {
  const response = await instance.get(`offers/${offerId}/stores`);
  return response.data;
}
