export default function parseBoolean(value) {
  if (typeof value === "boolean" || value === undefined || value === null) {
    return value;
  }

  if (typeof value === "string") {
    const v = JSON.parse(value);
    if (typeof v !== "boolean") {
      throw new Error(
        `The value "${value}" cannot be transformed to a boolean`
      );
    }
    return v;
  }

  throw new Error(`The value "${value}" cannot be transformed to a boolean`);
}
