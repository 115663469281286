import React from "react";
import { Link } from "react-router-dom";
import { useThemeContext } from "../context/ThemeContext";

export default function OfferCard({ id, title, description, video, image }) {
  const { theme } = useThemeContext();

  return (
    <div className="card mt-3">
      <div className="card-body">
        <h5 className="card-title text-uppercase" style={theme.text.primary}>
          {title}
        </h5>
        <div
          className="card-text mt-3"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {/* </div>
      {image ? (
        <img alt="Card cap" src="https://picsum.photos/318/180" width="100%" />
      ) : null}
      <div className="card-body"> */}
        <div className="d-flex justify-content-end mt-3">
          <Link className="card-link" to={`/questions/${id}`}>
            <button
              type="button"
              className="btn btn-lg btn-primary"
              style={theme.button.primary}
            >
              Aplicar
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
