import React from "react";
import { useFormContext } from "react-hook-form";
import { useThemeContext } from "../context/ThemeContext";

const RadioInput = React.forwardRef(
  ({ values, transformSelectedValue, ...props }, ref) => {
    const { theme } = useThemeContext();

    const {
      formState: { errors, isSubmitted },
    } = useFormContext();

    const error = errors[props.name];

    const classes = `form-check-input ${
      isSubmitted ? (!error ? "is-valid" : "is-invalid") : ""
    }`;

    return (
      <>
        {values.map(({ value, text }) => (
          <div key={`${value}_${text}`} className="form-check mt-4">
            <input
              {...props}
              className={classes}
              type="radio"
              ref={ref}
              value={value}
              id={`rb_${props.name}_${value}`}
            />
            <label
              className="form-check-label"
              style={theme.text.primary}
              htmlFor={`rb_${props.name}_${value}`}
            >
              {text || value}
            </label>
          </div>
        ))}

        {error && <div className="invalid-feedback">{error.message}</div>}
      </>
    );
  }
);

RadioInput.displayName = "RadioInput";

export default RadioInput;
