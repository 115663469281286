import React, { useContext, useMemo } from "react";
import { useSettingsContext } from "./SettingsContext";

const tacobellCR = {
  "{APP_TITLE}": "Taco Bell empleos - Costa Rica",
  "{APP_SHORT_TITLE}": "Taco Bell empleos",
  "{APP_LANDING_TITLE}": "¿Por qué trabajar en Taco Bell?",
  "{APP_LANDING_DESCRIPTION}": `
  <p>
    Somos una franquicia de restaurantes de comida rápida
    especializada en cocina Tex-Mex, esta cadena fue fundada en el
    año 1962 por Glen Bell en Estados Unidos, en nuestro corazón
    somos un negocio impulsado por la energía Live Más, donde la
    gente siente pasión por servir a la gente.
  </p>
  <p>
    Nuestra cultura es única, por lo que fomentamos la creatividad y
    alentamos la búsqueda de la pasión, somos auténticos para que te
    sintás en casa con la familia Taco Bell.
  </p>`,
};

const tacobellP = {
  ...tacobellCR,
  "{APP_TITLE}": "Taco Bell empleos - Panamá",
  Cantón: "Distrito",
  Distrito: "Corregimiento",
  "¿Tienes carnet de manipulación de alimentos?": "¿Tienes carnet verde?",
  "Secundaria incompleta": "Bachiller secundaria incompleta",
  "Secundaria completa": "Bachiller secundaria completa",
  "Bachiller universitario": "Licenciatura universitario",
};

const spoon = {
  "{APP_TITLE}": "Spoon empleos - Costa Rica",
  "{APP_SHORT_TITLE}": "Spoon empleos",
  "{APP_LANDING_TITLE}": "Quienes somos",
  "{APP_LANDING_DESCRIPTION}": `
  <p>
    En Spoon nos corre "dulce de leche por las venas", desde 1977
    somos una gran familia enfocada en superar las expectativas de los clientes
    cocinando momentos únicos para consentir al mundo con 
    productos de excelente calidad, servicio e innovación constante.
  </p>
  <p>
    Anhelamos estar presentes en todo momento con productos
    y experiencias excepcionales, de forma sostenible. 
  </p>`,
};

const wordings = {
  tacobellCR,
  tacobellP,
  spoon,
};

const resolveText = (themeId, key) => (wordings[themeId] || {})[key] || key;

const WordingsContext = React.createContext(null);

export function WordingsContextProvider({ children }) {
  const { themeId } = useSettingsContext();

  const value = useMemo(
    () => ({ getText: (key) => resolveText(themeId, key) }),
    [themeId]
  );

  return (
    <WordingsContext.Provider value={value}>
      {children}
    </WordingsContext.Provider>
  );
}

export function useWordingsContext() {
  return useContext(WordingsContext);
}
