import axios from "axios";
import { v4 as uuid } from "uuid";
import { getValueSS, setValueSS } from "../helpers/sessionStorage";

const instance = axios.create({
  timeout: 5000,
});

let session = getValueSS("sessionId", null);
if (!session) {
  session = uuid();
  setValueSS("sessionId", session);
}

export async function recordEvent({ type, name, offerId, candidateId, error }) {
  await instance.post("events", {
    session,
    type,
    name,
    offerId,
    candidateId,
    error,
  });
}
