import React, { useContext, useMemo } from "react";
import { useSessionStorageState } from "../hooks/useSessionStorageState";

const SessionContext = React.createContext(null);

export function SessionContextProvider({ children }) {
  const [offerId, setOfferId] = useSessionStorageState("offerId");
  const [candidateId, setCandidateId] = useSessionStorageState("candidateId");
  const [stepId, setStepId] = useSessionStorageState("stepId", "tyc");
  const [process, setProcess] = useSessionStorageState("process");

  const contextValue = useMemo(
    () => ({
      offerId,
      setOfferId,
      candidateId,
      setCandidateId,
      stepId,
      setStepId,
      process,
      setProcess,
    }),
    [
      offerId,
      setOfferId,
      candidateId,
      setCandidateId,
      stepId,
      setStepId,
      process,
      setProcess,
    ]
  );

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
}

export function useSessionContext() {
  return useContext(SessionContext);
}
