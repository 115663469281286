import axios from "axios";

const instance = axios.create({
  timeout: 5000,
});

export async function findProvinces() {
  const response = await instance.get("provinces");
  return response.data;
}

export async function findStates({ provinceId }) {
  const response = await instance.get(`provinces/${provinceId}/states`);
  return response.data;
}

export async function findMunicipalities({ provinceId, stateId }) {
  const response = await instance.get(
    `provinces/${provinceId}/states/${stateId}/municipalities`
  );
  return response.data;
}

export async function findStores({ provinceId }) {
  const response = await instance.get(`provinces/${provinceId}/stores`);
  return response.data;
}
