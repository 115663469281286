import {
  tyc,
  personalData,
  address,
  whiteCard,
  foodHandlingCard,
  pca,
  pcaOk,
  workingStatus,
  trainingStatus,
  friends,
  disability,
  congrats,
} from "./steps";

const flows = {
  default: (getText) => ({
    tyc: tyc(getText),
    personalData: personalData(getText),
    address: address(getText),
    foodHandlingCard: foodHandlingCard(getText),
    pca: pca(getText),
    pcaOk: pcaOk(getText),
    workingStatus: workingStatus(getText),
    trainingStatus: trainingStatus(getText),
    friends: friends(getText),
    disability: disability(getText),
    congrats: congrats(getText),
  }),
  tacobellP: (getText) => ({
    tyc: tyc(getText),
    personalData: personalData(getText),
    address: address(getText),
    whiteCard: whiteCard(getText),
    foodHandlingCard: foodHandlingCard(getText),
    pca: pca(getText),
    pcaOk: pcaOk(getText),
    workingStatus: workingStatus(getText),
    trainingStatus: trainingStatus(getText),
    friends: friends(getText),
    disability: disability(getText),
    congrats: congrats(getText),
  }),
};

const cachedFlows = {};

export function getFlow(themeId, getText) {
  let { flow, steps } = cachedFlows[themeId] || {};
  if (!flow) {
    flow = (flows[themeId] || flows.default)(getText);
    steps = Object.keys(flow);
    cachedFlows[themeId] = { flow, steps };
  }
  return { flow, steps };
}
