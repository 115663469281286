import React, { useState } from "react";
import OfferCard from "../components/OfferCard";
import { findOffers } from "../services/offers";
import { useThemeContext } from "../context/ThemeContext";
import { useSessionContext } from "../context/SessionContext";
import { useMount } from "../hooks/useMount";
import { useRecordEvent } from "../hooks/useRecordEvent";
import { useWordingsContext } from "../context/WordingsContext";

export default function Home() {
  const [offers, setOffers] = useState([]);

  const recordEvent = useRecordEvent();

  const { setOfferId, setStepId, setProcess } = useSessionContext();

  const { getText } = useWordingsContext();

  const { theme, setTheme } = useThemeContext();

  useMount(() => {
    recordEvent({
      type: "page",
      name: "Home",
    });

    setTheme("home");

    setOfferId(null);
    setStepId("tyc");
    setProcess(null);

    findOffers().then(setOffers);
  });

  return (
    <div className="tims-landing">
      <nav className="navbar sticky-top" style={theme.navbar}>
        <div className="tims-device-container">
          <h4 className="mt-0 mb-0">{getText("{APP_TITLE}")}</h4>
        </div>
      </nav>
      <img
        className="tims-landing-image"
        src={theme.landing.imageUrl}
        alt={theme.landing.imageAlt}
        width="100%"
      />
      <div className="tims-device-container">
        <div className="cardx mt-5 mb-5">
          <div className="card-body">
            <h3
              className="card-title text-uppercase"
              style={theme.text.primary}
            >
              {getText("{APP_LANDING_TITLE}")}
            </h3>
            <div
              className="card-text mt-3"
              dangerouslySetInnerHTML={{
                __html: getText("{APP_LANDING_DESCRIPTION}"),
              }}
            />
          </div>
        </div>
      </div>
      <div className="tims-device-container">
        <div className="mt-5 mb-5">
          <h3 className="text-uppercase" style={theme.text.primary}>
            Ofertas de empleo
          </h3>
          {offers.map((offer) => (
            <OfferCard key={offer.id} {...offer}></OfferCard>
          ))}
        </div>
      </div>
    </div>
  );
}
