import { useCallback } from "react";
import { recordEvent } from "../services/events";
import { useSessionContext } from "../context/SessionContext";

export function useRecordEvent() {
  const { offerId: defaultOfferId, candidateId: defaultCandidateId } =
    useSessionContext();

  const fn = useCallback(
    ({ type, name, error, offerId, candidateId }) => {
      recordEvent({
        type,
        name,
        error,
        offerId: offerId !== undefined ? offerId : defaultOfferId,
        candidateId:
          candidateId !== undefined ? candidateId : defaultCandidateId,
      });
    },
    [defaultCandidateId, defaultOfferId]
  );

  return fn;
}
