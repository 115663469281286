import axios from "axios";

const instance = axios.create({
  timeout: 5000,
});

export async function getSettings() {
  const response = await instance.get("settings");
  return response.data;
}
