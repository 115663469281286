import React from "react";
import { useParams } from "react-router-dom";
import QuestionsWizard from "../components/QuestionsWizard";
import { useThemeContext } from "../context/ThemeContext";
import { useSessionContext } from "../context/SessionContext";
import { useMount } from "../hooks/useMount";
import { useRecordEvent } from "../hooks/useRecordEvent";

export default function Questions() {
  const { offerId: offerIdParam } = useParams();

  const recordEvent = useRecordEvent();

  const { setTheme } = useThemeContext();

  const { offerId, setOfferId } = useSessionContext();

  useMount(() => {
    recordEvent({
      type: "page",
      name: "Onboarding",
      offerId: offerIdParam,
    });

    setTheme(null);

    setOfferId(offerIdParam);
  });

  return offerId ? (
    <div className="tims-device-container" style={{ minHeight: "100vh" }}>
      <QuestionsWizard />
    </div>
  ) : null;
}
