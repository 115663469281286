import React, { useState, useContext } from "react";
import { useMount } from "../hooks/useMount";
import { getSettings } from "../services/settings";

const SettingsContext = React.createContext(null);

export function SettingsContextProvider({ children }) {
  const [settings, setSettings] = useState(null);

  useMount(() => {
    const fn = async () => {
      const response = await getSettings();
      setSettings(response);
    };
    fn();
  });

  if (!settings) {
    return null;
  }

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
}

export function useSettingsContext() {
  return useContext(SettingsContext);
}
