import { useState, useCallback } from "react";
import { getValueSS, setValueSS } from "../helpers/sessionStorage";

export function useSessionStorageState(key, defaultValue = null) {
  const [value, _setValue] = useState(() => getValueSS(key, defaultValue));

  const setValue = useCallback(
    (newValue) => {
      _setValue(newValue);
      setValueSS(key, newValue);
    },
    [key]
  );

  return [value, setValue];
}
