import React from "react";
import { useFormContext } from "react-hook-form";
import { useThemeContext } from "../context/ThemeContext";

const SelectInput = React.forwardRef(({ label, values, ...props }, ref) => {
  const { theme } = useThemeContext();

  const {
    formState: { errors, isSubmitted },
  } = useFormContext();

  const error = errors[props.name];

  const classes = `form-select form-select-lg ${
    isSubmitted ? (!error ? "is-valid" : "is-invalid") : ""
  }`;

  return (
    <div className="mt-4">
      <label
        htmlFor={props.name}
        className="form-label fw-semibold"
        style={theme.text.primary}
      >
        {label}
      </label>
      <select
        {...props}
        ref={ref}
        id={props.name}
        className={classes}
        defaultValue=""
      >
        <option disabled value="">
          Elige un valor
        </option>
        {values.map(({ value, text }) => {
          return (
            <option key={value} value={value}>
              {text || value}
            </option>
          );
        })}
      </select>
      {error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  );
});

SelectInput.displayName = "SelectInput";

export default SelectInput;
