import axios from "axios";

const instance = axios.create({
  timeout: 5000,
});

export async function findByEmail({ email }) {
  const response = await instance.get(`candidates/findByEmail/${email}`);
  return response.data;
}
