export function getValueSS(key, defaultValue) {
  try {
    const currentValue = window?.sessionStorage.getItem(key);
    if (currentValue) {
      return JSON.parse(currentValue);
    }
    return defaultValue;
  } catch {
    return defaultValue;
  }
}

export function setValueSS(key, newValue) {
  if (newValue === null || newValue === undefined) {
    window?.sessionStorage.removeItem(key);
  } else {
    window?.sessionStorage.setItem(key, JSON.stringify(newValue));
  }
}
