import {
  findProvinces,
  findStates,
  findMunicipalities,
} from "../services/provinces";
import parseBoolean from "../helpers/parseBoolean";

export const tyc = (getText) => ({
  title: "Para empezar, necesitamos tú email personal",
  fields: [
    {
      name: "email",
      label: "Email",
      type: "email",
      validations: {
        maxLength: {
          value: 50,
          message: "El dato ingresado supera el tamaño permitido",
        },
        required: "Se requiere un email válido",
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "Se requiere un email válido",
        },
      },
    },
    {
      name: "tyc",      
      link:"https://timshr.com/terms.html",
      type: "tyccheckbox",
      validations: {
        required: "Debes aceptar los términos y condiciones para poder aplicar",
      },
    },
  ],
  index: false,
});

export const personalData = (getText) => ({
  title: "Necesitamos algunos datos personales",
  fields: [
    {
      name: "firstSurname",
      label: "Primer apellido",
      type: "text",
      validations: {
        maxLength: {
          value: 30,
          message: "El dato ingresado supera el tamaño permitido",
        },
        required: "Dato requerido",
      },
    },
    {
      name: "secondSurname",
      label: "Segundo apellido",
      type: "text",
      validations: {
        maxLength: {
          value: 30,
          message: "El dato ingresado supera el tamaño permitido",
        },
      },
    },
    {
      name: "name",
      label: "Nombre",
      type: "text",
      validations: {
        maxLength: {
          value: 20,
          message: "El dato ingresado supera el tamaño permitido",
        },
        required: "Dato requerido",
      },
    },
    {
      name: "documentId",
      label: "Cédula",
      type: "text",
      validations: {
        pattern: {
          value: /^[0-9]{7,15}$/,
          message: "El dato esperado debe contener entre 7 y 15 números",
        },
        required: "Dato requerido",
      },
    },
    {
      name: "gender",
      label: "Género",
      type: "select",
      values: [
        { value: "M", text: "Hombre" },
        { value: "F", text: "Mujer" },
      ],
      validations: { required: "Dato requerido" },
    },
    {
      name: "phone",
      label: "Teléfono",
      type: "text",
      validations: {
        pattern: {
          value: /^[0-9]{8,12}$/,
          message: "El dato esperado debe contener entre 8 y 12 números",
        },
        required: "Dato requerido",
      },
    },
  ],
});

export const address = (getText) => ({
  title: "También tú dirección",
  fields: [
    {
      name: "provinceId",
      label: "Provincia",
      type: "autocomplete",
      source: findProvinces,
      mapper: ({ id, name }) => ({ value: id, text: name }),
      validations: { required: "Dato requerido" },
    },
    {
      name: "stateId",
      label: getText("Cantón"),
      type: "autocomplete",
      source: findStates,
      params: ({ provinceId }) => (provinceId ? { provinceId } : false),
      mapper: ({ id, name }) => ({ value: id, text: name }),
      validations: { required: "Dato requerido" },
    },
    {
      name: "municipalityId",
      label: getText("Distrito"),
      type: "autocomplete",
      source: findMunicipalities,
      params: ({ provinceId, stateId }) =>
        provinceId && stateId
          ? {
              provinceId,
              stateId,
            }
          : false,
      mapper: ({ id, name }) => ({ value: id, text: name }),
      validations: { required: "Dato requerido" },
    },
    {
      name: "address",
      label: "Dirección",
      type: "long_text",
      rows: 3,
      validations: {
        maxLength: {
          value: 200,
          message: "El dato ingresado supera el tamaño permitido",
        },
        required: "Dato requerido",
      },
    },
  ],
});

export const availability = (getText) => ({
  title: "¿Cuál es tú disponibilidad laboral?",
  subtitle: ["Elige las opciones que se ajusten mejor a lo que buscas"],
  fields: [
    {
      name: "fullTimeAvailability",
      label: "Tiempo completo",
      type: "checkbox",
      validations: ({ getValues }) => ({
        validate: (value) => {
          return (
            value ||
            getValues().weekendsAvailability ||
            getValues().forHoursAvailability
          );
        },
      }),
    },
    {
      name: "weekendsAvailability",
      label: "Fines de semana",
      type: "checkbox",
      validations: ({ getValues }) => ({
        validate: (value) => {
          return (
            value ||
            getValues().fullTimeAvailability ||
            getValues().forHoursAvailability
          );
        },
      }),
    },
    {
      name: "forHoursAvailability",
      label: "Por horas",
      type: "checkbox",
      validations: ({ getValues }) => ({
        validate: (value) => {
          return (
            value ||
            getValues().fullTimeAvailability ||
            getValues().weekendsAvailability
          );
        },
      }),
    },
  ],
});

export const whiteCard = (getText) => ({
  title: getText("¿Tienes carnet blanco?"),
  fields: [
    {
      name: "whiteCard",
      type: "radio",
      values: [
        { value: "false", text: "No" },
        { value: "true", text: "Sí" },
      ],
      validations: { required: "Dato requerido" },
    },
    {
      name: "whiteCardExpirationMonth",
      label: "Vence en el mes de",
      type: "select",
      values: [
        { value: 1, text: "Enero" },
        { value: 2, text: "Febrero" },
        { value: 3, text: "Marzo" },
        { value: 4, text: "Abril" },
        { value: 5, text: "Mayo" },
        { value: 6, text: "Junio" },
        { value: 7, text: "Julio" },
        { value: 8, text: "Agosto" },
        { value: 9, text: "Septiembre" },
        { value: 10, text: "Octubre" },
        { value: 11, text: "Noviembre" },
        { value: 12, text: "Diciembre" },
      ],
      validations: { required: "Dato requerido" },
      showWhen: ({ whiteCard }) => parseBoolean(whiteCard),
    },
    {
      name: "whiteCardExpirationYear",
      label: "Del año",
      type: "select",
      values: Array.from({ length: 6 }, (_, index) => {
        var currentDate = new Date();
        return {
          value: currentDate.getFullYear() + index,
          text: currentDate.getFullYear() + index,
        };
      }),
      validations: { required: "Dato requerido" },
      showWhen: ({ whiteCard }) => parseBoolean(whiteCard),
    },
  ],
});

export const foodHandlingCard = (getText) => ({
  title: getText("¿Tienes carnet de manipulación de alimentos?"),
  fields: [
    {
      name: "foodHandlingCard",
      type: "radio",
      values: [
        { value: "false", text: "No" },
        { value: "true", text: "Sí" },
      ],
      validations: { required: "Dato requerido" },
    },
    {
      name: "foodHandlingCardExpirationMonth",
      label: "Vence en el mes de",
      type: "select",
      values: [
        { value: 1, text: "Enero" },
        { value: 2, text: "Febrero" },
        { value: 3, text: "Marzo" },
        { value: 4, text: "Abril" },
        { value: 5, text: "Mayo" },
        { value: 6, text: "Junio" },
        { value: 7, text: "Julio" },
        { value: 8, text: "Agosto" },
        { value: 9, text: "Septiembre" },
        { value: 10, text: "Octubre" },
        { value: 11, text: "Noviembre" },
        { value: 12, text: "Diciembre" },
      ],
      validations: { required: "Dato requerido" },
      showWhen: ({ foodHandlingCard }) => parseBoolean(foodHandlingCard),
    },
    {
      name: "foodHandlingCardExpirationYear",
      label: "Del año",
      type: "select",
      values: Array.from({ length: 6 }, (_, index) => {
        var currentDate = new Date();
        return {
          value: currentDate.getFullYear() + index,
          text: currentDate.getFullYear() + index,
        };
      }),
      validations: { required: "Dato requerido" },
      showWhen: ({ foodHandlingCard }) => parseBoolean(foodHandlingCard),
    },
  ],
});

export const pca = (getText) => ({
  title: "Te invitamos a responder un test…",
  subtitle: [
    "No te preocupes, son preguntas muy sencillas que nos permitirán conocerte mejor.",
    "Es importante que respondas este test para que tu solicitud quede completa, una vez finalizado, te invitamos a continuar con otras preguntas adicionales de tu información personal.",
  ],
  goTo: ({ pcaLink }) => pcaLink || "nextStep",
});

export const pcaOk = (getText) => ({
  title: "¡Ya casi terminamos! Queremos saber un poco más de ti",
  index: false,
});

export const workingStatus = (getText) => ({
  title: "¿Estás trabajando actualmente?",
  fields: [
    {
      name: "currentWorking",
      type: "radio",
      values: [
        { value: "false", text: "No" },
        { value: "true", text: "Sí" },
      ],
      validations: { required: "Dato requerido" },
    },
  ],
});

export const trainingStatus = (getText) => ({
  title: "¿Cuál es tu formación académica?",
  fields: [
    {
      name: "educationLevel",
      type: "radio",
      values: [
        { value: "Primaria incompleta" },
        { value: "Primaria completa" },
        { value: getText("Secundaria incompleta") },
        { value: getText("Secundaria completa") },
        { value: "Técnico (secundaria/otro)" },
        { value: getText("Bachiller universitario") },
      ],
      validations: { required: "Dato requerido" },
    },
  ],
});

export const friends = (getText) => ({
  title: "¿Tenés familiares trabajando con nosotros?",
  subtitle: ["Tu familia es muy importante para nosotros."],
  fields: [
    {
      name: "hasFriends",
      type: "radio",
      values: [
        { value: "false", text: "No" },
        { value: "true", text: "Sí" },
      ],
      validations: { required: "Dato requerido" },
    },
    {
      name: "friends",
      label: "Por favor escribe su nombre completo y parentesco",
      type: "long_text",
      rows: 5,
      validations: { required: "Dato requerido" },
      showWhen: ({ hasFriends }) => parseBoolean(hasFriends),
    },
  ],
});

export const disability = (getText) => ({
  title: "¿Tienes alguna discapacidad?",
  subtitle: ["Somos una empresa inclusiva y creemos en toda la gente."],
  fields: [
    {
      name: "hasDisability",
      type: "radio",
      values: [
        { value: "false", text: "No" },
        { value: "true", text: "Sí" },
      ],
      validations: { required: "Dato requerido" },
    },
    {
      name: "disability",
      label: "¿Cuál es tú discapacidad?",
      type: "long_text",
      rows: 5,
      validations: { required: "Dato requerido" },
      showWhen: ({ hasDisability }) => parseBoolean(hasDisability),
    },
  ],
});

export const congrats = (getText) => ({
  title: "¡Felicidades! Hemos terminado...",
  subtitle: [
    "Muchas gracias por aplicar a nuestra oferta de empleo.",
    "Revisaremos tu solicitud y nos pondremos en contacto. ",
  ],
  index: false,
});
